import { render, staticRenderFns } from "./OfferBenefitBadge.vue?vue&type=template&id=6e257a5d&scoped=true"
import script from "./OfferBenefitBadge.vue?vue&type=script&lang=ts"
export * from "./OfferBenefitBadge.vue?vue&type=script&lang=ts"
import style0 from "./OfferBenefitBadge.vue?vue&type=style&index=0&id=6e257a5d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@2.8.8_react-do_kl5bydc6qcrojdhla6tfmkl2em/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e257a5d",
  null
  
)

export default component.exports